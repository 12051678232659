define('ember-power-calendar/components/power-calendar-range/days', ['exports', 'ember-power-calendar/components/power-calendar/days', 'ember-power-calendar-utils'], function (exports, _days, _emberPowerCalendarUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _days.default.extend({
    // Methods
    buildDay: function buildDay(date, today, calendar) {
      var day = this._super.apply(this, arguments);

      var _EmberGetProperties = Ember.getProperties(calendar.selected || { start: null, end: null }, 'start', 'end'),
          start = _EmberGetProperties.start,
          end = _EmberGetProperties.end;

      if (start && end) {
        day.isSelected = (0, _emberPowerCalendarUtils.isBetween)(date, start, end, 'day', '[]');
        day.isRangeStart = day.isSelected && (0, _emberPowerCalendarUtils.isSame)(date, start, 'day');
        day.isRangeEnd = day.isSelected && (0, _emberPowerCalendarUtils.isSame)(date, end, 'day');
      } else {
        day.isRangeEnd = false;
        if (!start) {
          day.isRangeStart = false;
        } else {
          day.isRangeStart = day.isSelected = (0, _emberPowerCalendarUtils.isSame)(date, start, 'day');
          if (!day.isDisabled) {
            var diffInMs = Math.abs((0, _emberPowerCalendarUtils.diff)(day.date, start));
            day.isDisabled = diffInMs < calendar.minRange || calendar.maxRange !== null && diffInMs > calendar.maxRange;
          }
        }
      }
      return day;
    },
    dayIsSelected: function dayIsSelected() {
      return false;
    }
  });
});