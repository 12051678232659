define('ember-power-calendar/helpers/power-calendar-format-date', ['exports', 'ember-power-calendar-utils'], function (exports, _emberPowerCalendarUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.powerCalendarFormatDate = powerCalendarFormatDate;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function powerCalendarFormatDate(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 2),
        date = _ref3[0],
        format = _ref3[1];

    var locale = _ref2.locale;

    return (0, _emberPowerCalendarUtils.formatDate)(date, format, locale);
  }

  exports.default = Ember.Helper.helper(powerCalendarFormatDate);
});