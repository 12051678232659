define("ember-cli-airbrake/filters/session", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = setSession;
  function setSession(session) {
    return function setSession$filter(notice) {
      notice.session = session;
      return notice;
    };
  }
});