define('ember-power-calendar/components/power-calendar-range', ['exports', 'ember-power-calendar/components/power-calendar', 'ember-power-calendar/utils/computed-fallback-if-undefined', 'ember-power-calendar-utils'], function (exports, _powerCalendar, _computedFallbackIfUndefined, _emberPowerCalendarUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerCalendar.default.extend({
    daysComponent: 'power-calendar-range/days',
    proximitySelection: (0, _computedFallbackIfUndefined.default)(false),

    // CPs
    minRange: Ember.computed({
      get: function get() {
        return 86400000;
      },
      set: function set(_, v) {
        if (typeof v === 'number') {
          return v * 86400000;
        }
        return (0, _emberPowerCalendarUtils.normalizeDuration)(v === undefined ? 86400000 : v);
      }
    }),
    maxRange: Ember.computed({
      get: function get() {
        return null;
      },
      set: function set(_, v) {
        if (typeof v === 'number') {
          return v * 86400000;
        }
        return (0, _emberPowerCalendarUtils.normalizeDuration)(v === undefined ? 86400000 : v);
      }
    }),
    selected: Ember.computed({
      get: function get() {
        return { start: undefined, end: undefined };
      },
      set: function set(_, v) {
        if (v === undefined || v === null) {
          v = {};
        }
        return { start: (0, _emberPowerCalendarUtils.normalizeDate)(v.start), end: (0, _emberPowerCalendarUtils.normalizeDate)(v.end) };
      }
    }),

    currentCenter: Ember.computed('center', function () {
      var center = this.get('center');
      if (!center) {
        center = this.get('selected.start') || this.get('powerCalendarService').getDate();
      }
      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),

    publicAPI: Ember.computed('_publicAPI', 'minRange', 'maxRange', function () {
      var rangeOnlyAPI = this.getProperties('minRange', 'maxRange');
      return Ember.assign(rangeOnlyAPI, this.get('_publicAPI'));
    }),

    // Actions
    actions: {
      select: function select(day, calendar, e) {
        var range = this._buildRange(day);
        var _range$date = range.date,
            start = _range$date.start,
            end = _range$date.end;

        if (start && end) {
          var _get = this.get('publicAPI'),
              minRange = _get.minRange,
              maxRange = _get.maxRange;

          var diffInMs = Math.abs((0, _emberPowerCalendarUtils.diff)(end, start));
          if (diffInMs < minRange || maxRange && diffInMs > maxRange) {
            return;
          }
        }
        var action = this.get('onSelect');
        if (action) {
          action(range, calendar, e);
        }
      }
    },

    // Methods
    _buildRange: function _buildRange(day) {
      var selected = this.get('publicAPI.selected') || { start: null, end: null };

      var _EmberGetProperties = Ember.getProperties(selected, 'start', 'end'),
          start = _EmberGetProperties.start,
          end = _EmberGetProperties.end;

      if (this.get('proximitySelection')) {
        return this._buildRangeByProximity(day, start, end);
      }

      return this._buildDefaultRange(day, start, end);
    },
    _buildRangeByProximity: function _buildRangeByProximity(day, start, end) {
      if (start && end) {
        var changeStart = Math.abs((0, _emberPowerCalendarUtils.diff)(day.date, end)) > Math.abs((0, _emberPowerCalendarUtils.diff)(day.date, start));

        return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({
          date: {
            start: changeStart ? day.date : start,
            end: changeStart ? end : day.date
          }
        });
      }

      if ((0, _emberPowerCalendarUtils.isBefore)(day.date, start)) {
        return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({ date: { start: day.date, end: null } });
      }

      return this._buildDefaultRange(day, start, end);
    },
    _buildDefaultRange: function _buildDefaultRange(day, start, end) {
      if (start && !end) {
        if ((0, _emberPowerCalendarUtils.isAfter)(start, day.date)) {
          return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({ date: { start: day.date, end: start } });
        }
        return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({ date: { start: start, end: day.date } });
      }

      return (0, _emberPowerCalendarUtils.normalizeRangeActionValue)({ date: { start: day.date, end: null } });
    }
  });
});