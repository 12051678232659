define("ember-cli-airbrake/filters/environment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = setEnvironment;
  function setEnvironment(environment) {
    return function setEnvironment$filter(notice) {
      notice.context.environment = environment;
      return notice;
    };
  }
});