define('ember-cli-pubnub/utils/event-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.messageEventString = messageEventString;
  exports.presenceEventString = presenceEventString;


  /**
   * Message event string. Use this to seed
   * the pubnub event handlers.
   *
   * @method messageEventString
   *
   * @param {String} channel
   *   The name of the channel.
   *
   * @return {String}
   *   Message event string.
   */
  function messageEventString(channel) {
    (false && !(channel) && Ember.assert('channel required for message event', channel));

    return 'pn-message:' + channel;
  }

  /**
   * Presence event string. Use this to seed
   * the pubnub event handlers.
   *
   * @method presenceEventString
   *
   * @param {String} channel
   *   The name of the channel.
   *
   * @return {String}
   *   Presence event string.
   */
  function presenceEventString(channel) {
    (false && !(channel) && Ember.assert('channel required for presence event', channel));

    return 'pn-presence:' + channel;
  }
});