define('ember-cli-airbrake/services/airbrake', ['exports', 'ember-cli-airbrake/utils/get-client', 'ember-cli-airbrake/filters/environment', 'ember-cli-airbrake/reporters/logger', 'ember-cli-airbrake/filters/session'], function (exports, _getClient2, _environment, _logger, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.client = this._getClient();
    },

    // airbrakeJs client API
    notify: function notify() {
      var _client;

      (_client = this.client).notify.apply(_client, arguments);
    },


    // airbrakeJs client API
    addFilter: function addFilter() {
      var _client2;

      (_client2 = this.client).addFilter.apply(_client2, arguments);
    },


    // airbrakeJs client API
    addReporter: function addReporter() {
      var _client3;

      (_client3 = this.client).addReporter.apply(_client3, arguments);
    },


    // convenience API
    setSession: function setSession(session) {
      this.client.addFilter((0, _session.default)(session));
    },


    // private
    _getClient: function _getClient() {
      var config = getOwner(this).resolveRegistration('config:environment');

      var client = (0, _getClient2.default)(config, {
        reporters: [_logger.default],
        filters: [(0, _environment.default)(config.environment)]
      });

      return client;
    }
  });
});