define('ember-power-calendar/components/power-calendar-multiple', ['exports', 'ember-power-calendar/components/power-calendar', 'ember-power-calendar-utils'], function (exports, _powerCalendar, _emberPowerCalendarUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _powerCalendar.default.extend({
    daysComponent: "power-calendar-multiple/days",

    // CPs
    selected: Ember.computed({
      get: function get() {
        return undefined;
      },
      set: function set(_, v) {
        return Array.isArray(v) ? v.map(_emberPowerCalendarUtils.normalizeDate) : v;
      }
    }),
    currentCenter: Ember.computed('center', function () {
      var center = this.get('center');
      if (!center) {
        center = (this.get('selected') || [])[0] || this.get('powerCalendarService').getDate();
      }
      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),

    // Actions
    actions: {
      select: function select(day, calendar, e) {
        var action = this.get("onSelect");
        if (action) {
          action(this._buildCollection(day), calendar, e);
        }
      }
    },

    // Methods
    _buildCollection: function _buildCollection(day) {
      var selected = this.get("publicAPI.selected") || [];
      var values = [];
      var index = -1;
      for (var i = 0; i < selected.length; i++) {
        if ((0, _emberPowerCalendarUtils.isSame)(day.date, selected[i], "day")) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        values = [].concat(_toConsumableArray(selected), [day.date]);
      } else {
        values = selected.slice(0, index).concat(selected.slice(index + 1));
      }
      return (0, _emberPowerCalendarUtils.normalizeMultipleActionValue)({ date: values });
    }
  });
});