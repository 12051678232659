define('ember-cli-pubnub/services/pubnub', ['exports', 'pubnub'], function (exports, _pubnub) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = {
    create: function create() {
      var config = Ember.getWithDefault(this, 'config', {});

      if (!config || _typeof(config.pubnub) !== 'object') {
        throw new Ember.Error('Please set the `pubnub` property in the envrionment config');
      }

      return new _pubnub.default(config.pubnub);
    },


    config: null,
    isServiceFactory: true
  };
});