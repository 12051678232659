define('ember-cli-airbrake/utils/get-client', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getClient;


  var NullClient = Ember.Object.extend({
    notify: function notify() {},
    addFilter: function addFilter() {},
    setSession: function setSession() {}
  }); /* global airbrakeJs */

  function validateAirbrakeConfig(airbrakeConfig) {
    Ember.assert('airbrake projectId must be set in config', !!airbrakeConfig.projectId);
    Ember.assert('airbrake projectKey must be set in config', !!airbrakeConfig.projectKey);
  }

  function getClient(config) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var airbrakeConfig = config.airbrake;

    if (!airbrakeConfig) {
      return NullClient.create();
    } else {
      validateAirbrakeConfig(airbrakeConfig);

      var projectId = airbrakeConfig.projectId,
          projectKey = airbrakeConfig.projectKey;
      var client = new airbrakeJs.Client({ projectId: projectId, projectKey: projectKey });
      if (airbrakeConfig.host) {
        client.setHost(airbrakeConfig.host);
      }

      var reporters = options.reporters || [],
          filters = options.filters || [];

      reporters.forEach(function (r) {
        return client.addReporter(r);
      });
      filters.forEach(function (f) {
        return client.addFilter(f);
      });

      return client;
    }
  }
});