define('ember-cli-airbrake/reporters/logger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = reportNotice;


  function formatError(error) {
    return '[ember-cli-airbrake] reported error: "' + error.message + '"';
  }

  function reportNotice(notice) {
    notice.errors.forEach(function (error) {
      return Ember.Logger.error(formatError(error));
    });
  }
});